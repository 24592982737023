import React from "react"
import {useEffect} from "react"
import SEO from "../components/seo";


const ExternalVisit = (context) => {

    const id = context.pageContext.id;
    const slug = context.pageContext.slug;
    const isBrowser = () => typeof window !== "undefined";

    useEffect(
        () => {
            if(id){
                //console.log(4)

                if(isBrowser()){
                    //console.log("Looking good!")
                    window.location.replace("https://out.gratis.no/"+id+"");
                }

            }
        },[id]
    );

    return (
        <>
            <SEO
                title={'Ekstern lenke | Gratis.no'}
                meta={[{name: 'robots',content: 'noindex, nofollow'},{name: 'robots',content: 'noindex'}]}
            />
            <div style={{display:'flex',height:'100vh',justifyContent:'center',flexDirection: 'row',alignContent:'center',alignItems:'center',fontSize:'130%'}}>
                <div>Laster ekstern side...</div>
            </div>
        </>
    )
}

export default ExternalVisit